<template>

  <div>

    <!-- Filters -->
    <ticket-filter
      :search-query="searchQuery"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('Show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('entries')}}</label>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end">
            <spinner-loader />
            <feather-icon
              icon="RefreshCwIcon"
              size="21"
              class="cursor-pointer"
              @click="refetchData()"
              v-if="!$store.getters.getLoader"
            />
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(createdDate)="data">
          {{ formatDateTime(data.item.createdDate) }}
        </template>

        <template #cell(isRead)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              variant="warning"
              v-if="data.item.isRead"
            >
              <span class="align-text-top text-capitalize">Mark As Read</span>
            </b-badge>

            <b-badge
              pill
              variant="warning"
              v-else
            >
              <span class="align-text-top text-capitalize">Pending</span>
            </b-badge>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(ticketStatus)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              variant="danger"
              v-if="data.item.ticketStatus ===  0"
            >
              <span class="align-text-top text-capitalize">New Message</span>
            </b-badge>
          </div>
        </template>

        <template #cell(ticketType)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              variant="warning"
              v-if="data.item.ticketType ===  0"
            >
              <span class="align-text-top text-capitalize">G25</span>
            </b-badge>
            <b-badge
              pill
              variant="info"
              v-if="data.item.ticketType ===  1"
            >
              <span class="align-text-top text-capitalize">Traith & Health</span>
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          
          <b-link :to="{ name: 'manage-ticket-detail', params: { id: data.item.ticketNo } }">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0"
              size="sm">
              <feather-icon
                icon="AlignJustifyIcon"
                class="mr-50"
                size="16"
              />
              {{ $t('See Details') }}
            </b-button>
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { avatarText, formatDateTime } from '@core/utils/filter'

import vSelect from 'vue-select'

import TicketFilter from './TicketFilter.vue'
import useOpenTicketList from './useOpenTicketList'

export default {
  components: {
    TicketFilter,
    vSelect,
  },
  methods: {
    formatDateTime,
    addItem() {
      this.$router.push({ name: 'manage-preset-region-grup-save', params: { id: 0 } });
    },
  },
  setup() {
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      refetchData,
    } = useOpenTicketList()

    return {

      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
