var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Sample ID'),"label-for":"sampleID"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sampleID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sampleID","state":errors.length > 0 ? false:null},model:{value:(_vm.sampleID),callback:function ($$v) {_vm.sampleID=$$v},expression:"sampleID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Population'),"label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Location'),"label-for":"location"}},[_c('validation-provider',{attrs:{"rules":"required","name":"location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"location","state":errors.length > 0 ? false:null},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Date'),"label-for":"date"}},[_c('validation-provider',{attrs:{"rules":"required","name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","state":errors.length > 0 ? false:null},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DatingMethod'),"label-for":"datingMethod"}},[_c('validation-provider',{attrs:{"rules":"required","name":"datingMethod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"datingMethod","state":errors.length > 0 ? false:null},model:{value:(_vm.datingMethod),callback:function ($$v) {_vm.datingMethod=$$v},expression:"datingMethod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('yDNA (Terminal Mutation)'),"label-for":"yDNATM"}},[_c('validation-provider',{attrs:{"rules":"required","name":"yDNATM"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"yDNATM","state":errors.length > 0 ? false:null},model:{value:(_vm.yDNATM),callback:function ($$v) {_vm.yDNATM=$$v},expression:"yDNATM"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('yDNA (ISOGG)'),"label-for":"yDNAISOGG"}},[_c('validation-provider',{attrs:{"rules":"required","name":"yDNAISOGG"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"yDNAISOGG","state":errors.length > 0 ? false:null},model:{value:(_vm.yDNAISOGG),callback:function ($$v) {_vm.yDNAISOGG=$$v},expression:"yDNAISOGG"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('mtDNA'),"label-for":"mtDNA"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mtDNA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mtDNA","state":errors.length > 0 ? false:null},model:{value:(_vm.mtDNA),callback:function ($$v) {_vm.mtDNA=$$v},expression:"mtDNA"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Doi'),"label-for":"doi"}},[_c('validation-provider',{attrs:{"rules":"required","name":"doi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"doi","state":errors.length > 0 ? false:null},model:{value:(_vm.doi),callback:function ($$v) {_vm.doi=$$v},expression:"doi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Study'),"label-for":"study"}},[_c('validation-provider',{attrs:{"rules":"required","name":"study"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"study","state":errors.length > 0 ? false:null},model:{value:(_vm.study),callback:function ($$v) {_vm.study=$$v},expression:"study"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('G25 Coordinate'),"label-for":"g25Coordinate"}},[_c('b-form-textarea',{staticClass:"source-input",attrs:{"id":"textarea-auto-height","placeholder":"Tall textarea","rows":"2","no-auto-shrink":""},model:{value:(_vm.g25Coordinate),callback:function ($$v) {_vm.g25Coordinate=$$v},expression:"g25Coordinate"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Latitude'),"label-for":"latitude"}},[_c('b-form-input',{attrs:{"id":"latitude"},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=$$v},expression:"latitude"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Longitude'),"label-for":"longitude"}},[_c('b-form-input',{attrs:{"id":"longitude"},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=$$v},expression:"longitude"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.saveItem.bind(this, true),"onClickSave":_vm.saveItem.bind(this, false)}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }