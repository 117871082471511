<template>
  <b-card>
    <b-button-group>
      <b-button @click="saveSampleCoordinates()" variant="info" size="sm">
        <b-spinner
          v-if="$store.getters.getLoader"
          class="mr-1"
          small
          variant="light"
        />
        <feather-icon
          v-if="!$store.getters.getLoader"
          icon="SaveIcon"
          class="mr-50"
          size="16"
        />
        <span class="align-middle">{{ $t('Save') }}</span>
      </b-button>

      <b-button @click="addNewSample()" variant="warning" size="sm">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Add New') }}</span>
      </b-button>
    </b-button-group>
    
    <b-table
      class="mt-2"
      ref="refListTable"
      :fields="fields"
      :items="sampleCoordinateList"
    >
      <template #cell(sample)="data">
        <b-form-input id="sample" v-model="data.item.sample" />
      </template>

      <template #cell(latitude)="data">
        <b-form-input id="latitude" v-model="data.item.latitude" />
      </template>

      <template #cell(longitude)="data">
        <b-form-input id="longitude" v-model="data.item.longitude" />
      </template>

      <template #cell(actions)="data">
        <div class="text-right">
          <feather-icon
            :id="`item-row-${data.item.id}-remove-icon`"
            @click="removeSample(data.item)"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </div>
      </template>

    </b-table>
  </b-card>
</template>

<script>

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Services
import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

export default {
  components: {
  },
  data() {
    return {
      /* eslint-disable */
      fields: [
        {
          key: 'sample',
          label: 'sample',
        },
        {
          key: 'latitude',
          label: 'latitude',
          thStyle: { width: '20%' },
        },
        {
          key: 'longitude',
          label: 'longitude',
          thStyle: { width: '20%' },
        },
        { 
          key: 'actions', 
          class: 'text-right',
        },
      ],
      sampleCoordinate: {
        id: 0,
        sample: null,
        latitude: null,
        longitude: null,
      },
      sampleCoordinateList: [],
    }
  },
  methods: {
    loadSampleCoordinates() {
      const paramId = this.$router.currentRoute.params.id;
      ApiService.get(`AncientProxDataSampleCoordinate/${paramId}`, {}, this)
        .then(coordinatesResponse => {
          if (coordinatesResponse.statusCode === 200) {
            this.sampleCoordinateList = coordinatesResponse.result;
          } else {
            AlertService.error(this, coordinatesResponse.message)
          }
        });
    },
    saveSampleCoordinates() {
      const paramId = this.$router.currentRoute.params.id;
      const query = {
        ancientProxDataId: paramId,
        sampleCoordinates: this.sampleCoordinateList,
      };

      ApiService.post(`AncientProxDataSampleCoordinate`, query, this)
        .then(coordinatesResponse => {
          if (coordinatesResponse) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: coordinatesResponse.message },
            });
            this.loadSampleCoordinates();
          }  else {
            AlertService.error(this, coordinatesResponse.message)
          }
        });
    },
    addNewSample() {
      this.sampleCoordinateList.push(this.sampleCoordinate);
    },
    removeSample(item) {
      if (item.id > 0) {
        ApiService.get(`AncientProxDataSampleCoordinate/delete/${item.id}`, {}, this)
          .then(coordinatesResponse => {
            if (coordinatesResponse.statusCode === 200) {
              const index = this.sampleCoordinateList.indexOf(item);
              if (index > -1) {
                this.sampleCoordinateList.splice(index, 1);
              }
            } else {
              AlertService.error(this, coordinatesResponse.message)
            }
          });
      } else {
        const index = this.sampleCoordinateList.indexOf(item);
        if (index > -1) {
          this.sampleCoordinateList.splice(index, 1);
        }
      }
    },
  },
  computed: {
    samples() {
      return this.$store.getters['ancientProxManagement/getAncientForm'].samples;
    },
  },
  mounted() {
    this.loadSampleCoordinates();
  }
}
</script>

<style scoped>
</style>
