<template>
  <b-card>
      <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Sample ID')" label-for="sampleID">
                  <validation-provider #default="{ errors }" rules="required" name="sampleID">
                    <b-form-input id="sampleID" v-model="sampleID" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Population')" label-for="name">
                  <validation-provider #default="{ errors }" rules="required" name="name">
                    <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Location')" label-for="location">
                  <validation-provider #default="{ errors }" rules="required" name="location">
                    <b-form-input id="location" v-model="location" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Date')" label-for="date">
                  <validation-provider #default="{ errors }" rules="required" name="date">
                    <b-form-input id="date" v-model="date" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('DatingMethod')" label-for="datingMethod">
                  <validation-provider #default="{ errors }" rules="required" name="datingMethod">
                    <b-form-input id="datingMethod" v-model="datingMethod" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('yDNA (Terminal Mutation)')" label-for="yDNATM">
                  <validation-provider #default="{ errors }" rules="required" name="yDNATM">
                    <b-form-input id="yDNATM" v-model="yDNATM" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('yDNA (ISOGG)')" label-for="yDNAISOGG">
                  <validation-provider #default="{ errors }" rules="required" name="yDNAISOGG">
                    <b-form-input id="yDNAISOGG" v-model="yDNAISOGG" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('mtDNA')" label-for="mtDNA">
                  <validation-provider #default="{ errors }" rules="required" name="mtDNA">
                    <b-form-input id="mtDNA" v-model="mtDNA" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Doi')" label-for="doi">
                  <validation-provider #default="{ errors }" rules="required" name="doi">
                    <b-form-input id="doi" v-model="doi" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Study')" label-for="study">
                  <validation-provider #default="{ errors }" rules="required" name="study">
                    <b-form-input id="study" v-model="study" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                  <b-form-group :label="$t('G25 Coordinate')" label-for="g25Coordinate">
                      <b-form-textarea
                          id="textarea-auto-height"
                          v-model="g25Coordinate"
                          class="source-input"
                          placeholder="Tall textarea"
                          rows="2"
                          no-auto-shrink
                      />
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Latitude')" label-for="latitude">
                  <b-form-input id="latitude" v-model="latitude" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Longitude')" label-for="longitude">
                  <b-form-input id="longitude" v-model="longitude" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import { createHelpers } from 'vuex-map-fields';
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

const { mapFields } = createHelpers({
  getterType: 'presetIndividualManage/getAncientField',
  mutationType: 'presetIndividualManage/updateAncientField',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      max,
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
          this.$store.dispatch('presetIndividualManage/fetchPresetIndividual', { id: paramId })
          .then(response => {
              if (response.statusCode === 200) {
              this.$store.commit('presetIndividualManage/setAncientField', response.result);
              } else {
              AlertService.error(this, response.message)
              }
          })
      }
    },
    saveItem(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const query = this.$store.getters['presetIndividualManage/getAncientForm'];
          this.$store.dispatch('presetIndividualManage/savePresetIndividual', query)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })

                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.$store.commit('presetIndividualManage/setAncientField', response.result);
                  this.$router.push({ name: 'manage-ancient-individual-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },
  },
  computed: {
    ...mapFields([
      'id',
      'isActive',
      'sampleID',
      'date',
      'datingMethod',
      'name',
      'mtDNA',
      'yDNATM',
      'yDNAISOGG',
      'location',
      'g25Coordinate',
      'latitude',
      'longitude',
      'study',
      'doi',
    ]),
  },
  created() {
    this.$store.commit('presetIndividualManage/resetAncientField');
    this.fetchItem();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
